/* @import 'bootstrap/dist/css/bootstrap.min.css'; */
@tailwind base;
@tailwind components;
@tailwind utilities;
@import url('https://fonts.googleapis.com/css2?family=Libre+Baskerville:wght@700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Noto+Serif&display=swap');



body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
@import url('https://fonts.googleapis.com/css2?family=Oswald:wght@200;300;400;500;600;700&display=swap');

@font-face{
  font-family: "Oswald";
  src: url('https://fonts.googleapis.com/css2?family=Oswald:wght@400&display=swap');
}

.header-title {
  font-family: 'Libre Baskerville', serif;
}

li {
  font-family: 'Libre Baskerville', serif;
}

/* ol {
  list-style: numbers;
} */

.bodyText {
  font-family: sans-serif;
}

.text-body {
  font-family: 'Noto Serif', serif;
}

.contactButton {
  position: fixed;
  bottom: -4px;
  right: 10px;
}
